/* eslint-disable */
import { BaseDTO } from "shared";
import { User } from "src/screens/users/types";
import { Package } from "../Packages/types";

export class BookingTable {
    id: number;
    bookingDate: string;
    scheduledDate: string;
    notes: string;
    status: string;
    userName: string;
    packageName: string;
}

export class BookingsDTO extends BaseDTO {
    id: number;
    bookingDate: string;
    scheduledDate: string;
    packageId: number;
    notes: string;
    status: string;
    user: User;
    package: Package;

    static fromDTO = (dto: BookingsDTO): BookingTable => ({
        id: dto.id,
        bookingDate: dto.bookingDate,
        scheduledDate: dto.scheduledDate,
        notes: dto.notes,
        status: dto.status,
        userName: dto.user.name,
        packageName: dto.package.name,
    });
}
