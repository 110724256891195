import {
  baseScreen,
} from 'hoc';
import React,
{
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';

import {
  PackageModelView,
} from '../packages/types';

const packagesForm: React.FC = () => {
  const {
    backend: {
      packages,
    },
  } = useStores();
  const history = useHistory();
  const {
    mode,
  } = useParams() as any;

  const [
    viewModeEnabled,
  ] = useState(mode === 'view');

  const detailForm = new FormFieldsBuilder<any, PackageModelView['detail']>()
    .addField('detailKey', {
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired() as any,
      title: 'Key',
      fieldOptions: {},
    })
    .addField('detailValue', {
      type: GeneralFormFields.INPUT_FIELD,
      title: 'Value',
      validationSchema: Yup.string().notRequired() as any,
      fieldOptions: {},
    });

  const packageForm = new FormFieldsBuilder<PackageModelView>()
    .addField('name', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('NAME'),
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
      fieldOptions: {},
    })
    .addField('description', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('DESCRIPTION'),
      fieldOptions: {},
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
    })
    .addField('diagnosis', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('DIAGNOSIS'),
      fieldOptions: {},
      validationSchema: Yup.string().notRequired().nullable() as any,
    })
    .addField('imagingInvestigation', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('IMAGING_INVESTIGATION'),
      fieldOptions: {},
      validationSchema: Yup.string().notRequired().nullable() as any,
    })
    .addField('lapInvestigation', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('LAP_INVESTIGATION'),
      fieldOptions: {},
      validationSchema: Yup.string().notRequired().nullable() as any,
    })
    .addField('surgeryDayEssentials', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('SURGERY_DAY_ESSENTIALS'),
      fieldOptions: {},
      validationSchema: Yup.string().notRequired().nullable() as any,
    })
    .addField('price', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('PRICE'),
      fieldOptions: {},
      validationSchema: Yup.number().required(i18n.t('REQUIRED_FIELD')),
    })
    .addField('duration', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('DURATION'),
      fieldOptions: {},
      validationSchema: Yup.number().required(i18n.t('REQUIRED_FIELD')),
    })
    .addField('details', {
      title: i18n.t('PACKAGE_DETAILS'),
      type: GeneralFormFields.FIELD_ARRAY,
      validationSchema: Yup.array().notRequired() as any,
      fieldOptions: {
        formData: {
          title: i18n.t('NAME'),
          type: GeneralFormFields.NESTED_FORM,
          fieldOptions: {
            formData: detailForm.formFields,
          },
        },
      },
    });

  return (
    <GeneralFormView<any, PackageModelView>
      title={i18n.t('MEMBERSHIP')}
      identifier="id"
      defaultLang=""
      viewMode={viewModeEnabled}
      otherLanguages={[]}
      formData={packageForm.formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          await packages.update(Number(id), {
            name: data.name,
            description: data.description,
            imagingInvestigation: data.imagingInvestigation,
            lapInvestigation: data.lapInvestigation,
            surgeryDayEssentials: data.surgeryDayEssentials,
            diagnosis: data.diagnosis,
            price: data.price,
            duration: data.duration,
            details: data.details,
          });

          history.push('/system-setup/system-setup/packages');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async (id) => {
        const res = await packages.getOne(id);
        return Promise.resolve(new PackageModelView(res));
      }}
    />
  );
};

export const PackagesForm = baseScreen(packagesForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
