/* eslint-disable prefer-destructuring */
import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';

import {
  PackageUploader,
} from '../packages/types';

const packagesForm: React.FC = () => {
  const {
    backend: {
      packages,
      serviceProviders,
    },
  } = useStores();
  const [
    sProvidersList,
    setSProvidersList,
  ] = useState<{title: string, value: string}[]>([]);
  useEffect(() => {
    const getData = async () => {
      const list = await serviceProviders.getAll({});
      setSProvidersList(list.map((item) => ({
        title: item.user.name,
        value: item.id as unknown as string,
      })));
    };

    getData();
  }, []);
  return (
    <GeneralFormView<any, PackageUploader>
      title={i18n.t('PACKAGES_UPLOADER')}
      identifier="id"
      defaultLang=""
      otherLanguages={[]}
      formData={new FormFieldsBuilder<PackageUploader>()
        .addField('serviceProviderIds', {
          type: GeneralFormFields.SELECT_FIELD,
          title: i18n.t('SELECT_SERVICE_PROVIDER'),
          validationSchema: Yup.array().min(1, i18n.t('REQUIRED_FIELD')) as any,
          fieldOptions: {
            isMultiple: false,
            items: sProvidersList,
          },
        })
        .addField('files', {
          type: GeneralFormFields.FILE_PICKER,
          title: i18n.t('NAME'),
          validationSchema: Yup.mixed()
            .test('fileType', 'File must be Excel (.xlsx or .xls)', (files) => {
              if (!files[0]) return false;
              const file = files[0].file;
              return ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'].includes(file.type);
            })
            .test('fileSize', 'File size must be less than 5MB', (files) => {
              if (!files[0]) return false;
              const file = files[0].file;
              return file.size <= 5 * 1024 * 1024;
            })
            .test('fileExtension', 'File must have .xlsx or .xls extension', (files) => {
              if (!files[0]) return false;
              const file = files[0].file;
              const extension = file.name.split('.').pop().toLowerCase();
              return ['xlsx', 'xls'].includes(extension);
            })
            .required('File is required') as any,
          fieldOptions: {
            size: 200,
            accept: 'images/*',
            multipleImages: false,
          },
        })
        .formFields}
      isFetchSuccessful
      update={async () => {}}
      create={async (data) => {
        try {
          const form = new FormData();
          form.append('file', data.files[0].file);
          packages.uploadExcel(
            Number(data.serviceProviderIds[0]),
            form,
          );
          return Promise.resolve(1);
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async () => Promise.resolve(new PackageUploader())}
    />
  );
};

export const PackagesUploader = baseScreen(packagesForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
