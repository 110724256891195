import {
  baseScreen,
} from 'hoc';
import React,
{
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  // useHistory,
  useParams,
} from 'react-router';
import * as Yup from 'yup';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';

import {
  BookingModelView,
} from '../packageBookings/types';

const bookingsForm: React.FC = () => {
  const {
    backend: {
      packageBooking,
      packages,
    },
  } = useStores();
  // const history = useHistory();
  const {
    mode,
  } = useParams() as any;
  const [
    packageList,
    setPackageList,
  ] = useState<{title: string, value: string}[]>([]);

  const [
    viewModeEnabled,
  ] = useState(mode === 'view');
  useEffect(() => {
    const getPackages = async () => {
      const packs = await packages.getAll({});
      setPackageList(packs.map((item) => ({
        title: item.name,
        value: String(item.id),
      })));
    };
    getPackages();
  }, []);

  const packageForm = new FormFieldsBuilder<BookingModelView>();

  if (mode === 'edit') {
    packageForm.addField('packageId', {
      title: 'Package',
      type: GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup.array()
        .of(Yup.string().required())
        .min(1, i18n.t('REQUIRED_FIELD'))
        .required() as any,
      fieldOptions: {
        isMultiple: false,
        items: packageList,
      },
    });
  }

  if (mode === 'view') {
    packageForm
      .addField('packageName', {
        type: GeneralFormFields.TEXT_AREA,
        title: i18n.t('PACKAGE'),
        fieldOptions: {},
      // validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
      });
  }

  packageForm
    .addField('bookingDate', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('BOOKING_DATE'),
      fieldOptions: {},
      validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
    })
    .addField('scheduledDate', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('SCHEDULED_DATE'),
      fieldOptions: {},
      validationSchema: Yup.string().notRequired().nullable() as any,
    })
    .addField('status', {
      title: i18n.t('STATUS'),
      type: GeneralFormFields.SELECT_FIELD,
      validationSchema: Yup.array()
        .of(Yup.string().required())
        .min(1, i18n.t('REQUIRED_FIELD'))
        .required() as any,
      fieldOptions: {
        isMultiple: false,
        items: [
          {
            title: 'Pending', value: 'pending',
          },
          {
            title: 'Confirmed', value: 'confirmed',
          },
          {
            title: 'Completed', value: 'completed',
          },
          {
            title: 'Cancelled', value: 'canceled',
          },
        ],
      },
    })
    .addField('notes', {
      type: GeneralFormFields.TEXT_AREA,
      title: i18n.t('NOTES'),
      fieldOptions: {},
      validationSchema: Yup.string().notRequired().nullable() as any,
    });

  return (
    <GeneralFormView<any, BookingModelView>
      title={i18n.t('MEMBERSHIP')}
      identifier="id"
      defaultLang=""
      viewMode={viewModeEnabled}
      otherLanguages={[]}
      formData={packageForm.formFields}
      isFetchSuccessful
      update={async (id, data) => {
        try {
          await packageBooking.update(Number(id), {
            packageId: Number(data.packageId[0]),
            status: data.status[0],
            bookingDate: data.bookingDate,
            scheduledDate: data.scheduledDate,
            notes: data.notes,
          });

          // history.push('/package/bookings');
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      }}
      get={async (id) => {
        const res = await packageBooking.getOne(id);
        const data = new BookingModelView(res);
        return Promise.resolve(data);
      }}
    />
  );
};

export const BookingsForm = baseScreen(bookingsForm, {
  allowedRoles: ['ADMIN', 'NONE'],
});
