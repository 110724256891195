/* eslint-disable */
import {
    action,
    observable,
} from 'mobx';
import {
    BackendEntity,
    ListBackendEntity,
    ModelOf,
} from 'utils';
import {
    BaseBackendStore,
} from '../types';
import {
    packages,
    aPackage,
    packageTemplate,
    uploadServiceProviderPackages,
    uploadDoctorPackages
} from './requests'
import {
    PackagesDTO
} from './types'
import {
    BackendStores,
} from '..';

export class PackagesStore extends BaseBackendStore {
    @observable private _entityList: PackagesDTO[] = [];

    @action public getAll = async (options: any): Promise<PackagesDTO[]> => {
        console.log(options);
        const response = await this.connections.backend
            .httpGet(`${packages}`);
        return response;
    }

    @observable entityList = new ListBackendEntity(
        this,
        '_entityList',
        this.getAll,
    );

    constructor(
        public parent: BackendStores,
    ) {
        super();
        this.makeObservable();
        this.registerObservableDrivers();
    };

    @observable private _selectedEntity: PackagesDTO;

    @observable selectedEntity = new BackendEntity(
        this,
        '_selectedEntity',
        this.getOne,
    );

    @action
    public async create(data: Partial<PackagesDTO>): Promise<void> {
        try {
            await this.connections.backend.httpPost(packages, data);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error.response.data.message);
        }
    }

    @action
    public async getOne(id: string): Promise<ModelOf<PackagesDTO>> {
        const response = await this.connections.backend.httpGet(aPackage(parseInt(id, 10)));
        this.selectedEntity = response;
        return Promise.resolve(response);
    }

    @action update = async (id: number, data: Partial<PackagesDTO>) => {
        try {
            const response = await this.connections.backend.httpPut(aPackage(id), data);
            return Promise.resolve(response);
        } catch (error: any) {
            return Promise.reject(error.response.data.message);
        }
    };

    @action
    public async delete(id: number): Promise<void> {
        const response = await this.connections.backend
            .httpDelete(aPackage(id));
        return Promise.resolve(response);
    }

    @action
    public async downloadTemplate(): Promise<any> {
        const response = await this.connections.backend
            .httpGet(packageTemplate, { responseType: 'blob' });

        return Promise.resolve(response);
    }

    public async uploadExcel(id: number, formData: FormData, isServiceProvider: boolean = true): Promise<void> {
        const url = isServiceProvider ? uploadServiceProviderPackages(id) : uploadDoctorPackages(id);
        await this.connections.backend
            .httpPost(url, formData,{
                headers: {
                    "content-type": "multipart/form-data",
                }
            });

        return Promise.resolve();
    }
}