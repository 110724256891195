import {
  BookingsDTO,
} from 'src/stores/backend/PackageBookingStore/types';
import {
  BaseFormViewModel,
} from '../types';

export type BookingStatus = 'pending'| 'confirmed'| 'completed'| 'canceled';

export class BookingModelView extends BaseFormViewModel<BookingsDTO> {
  id: number;

  packageName: string;

  packageId: string[];

  bookingDate: string;

  scheduledDate: string;

  notes: string;

  status: BookingStatus[];

  constructor(dto: BookingsDTO) {
    super();
    this.id = dto.id;
    this.bookingDate = dto.bookingDate;
    this.scheduledDate = dto.scheduledDate;
    this.notes = dto.notes;
    this.status = [dto.status as BookingStatus];
    this.packageId = [String(dto.package.id)];
    this.packageName = dto.package.name;
  }

  toDTO(): BookingsDTO {
    return {} as BookingsDTO;
  }
}

export default BookingsDTO;
