import {
  PackageDetail,
  PackagesDTO,
  PackagesUploaderDTO,
} from 'src/stores/backend/Packages/types';
import {
  BaseFormViewModel,
} from '../types';

export interface Detail {
  id: number;
  detailKey: string;
  detailValue: string;
}

export interface User {
  id: number;
  name: string;
}

export interface ServiceProvider {
  id: number;
  user: User;
}

export class PackageTable {
  id: number;

  name: string;

  description: string;

  price: number;

  duration: number;

  providerName: string;

  doctorName: string;
}

export class PackageModelView extends BaseFormViewModel<PackagesDTO> {
  id: number;

  name: string;

  description: string;

  diagnosis: string;

  imagingInvestigation: string;

  lapInvestigation: string;

  surgeryDayEssentials: string;

  price: number;

  duration: number;

  detail: Detail;

  details?: PackageDetail[];

  providerName: string;

  providerId: string[];

  doctorName: string;

  doctorId: string[];

  constructor(data: PackagesDTO) {
    super();
    this.name = data.name;
    this.description = data.description;
    this.price = Number(data.price);
    this.duration = Number(data.duration);
    this.details = data.details;
    this.providerId = [];
    if (data.serviceProvider) {
      this.providerId.push(`${data.serviceProvider.id}`);
    }
    this.providerName = data.serviceProvider?.user?.name ?? '';
    this.doctorId = [];
    if (data.doctor) {
      this.doctorId.push(`${data.doctor?.id}`);
    }
    this.doctorName = data.doctor?.user?.name ?? '';
    this.diagnosis = data.diagnosis;
    this.imagingInvestigation = data.imagingInvestigation;
    this.surgeryDayEssentials = data.surgeryDayEssentials;
    this.lapInvestigation = data.lapInvestigation;
  }

  toDTO(): PackagesDTO {
    return {} as PackagesDTO;
  }
}

export class PackageUploader extends BaseFormViewModel<PackagesUploaderDTO> {
  id?: number;

  serviceProviderIds: string[];

  files: any[];

  toDTO(): PackagesUploaderDTO {
    return {} as PackagesUploaderDTO;
  }
}

export default PackagesDTO;
