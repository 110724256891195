/* eslint-disable */
import { BaseDTO } from "shared";
import { ServiceProvidersDTO } from "../ServiceProvidersStore/types";
import { PackageTable } from "src/screens/packages/types";
import { Doctor } from "src/screens/doctors/types";

export interface Package {
    id: number;
    name: string;
    description: string;
    diagnosis: string;
    imagingInvestigation: string;
    price: number;
    duration: number;
    serviceProvider: ServiceProvidersDTO
    doctor: Doctor;
    details: PackageDetail[];
}

export class PackagesDTO extends BaseDTO {
    id: number;
    name: string;
    description: string;
    diagnosis: string;
    imagingInvestigation: string;
    surgeryDayEssentials: string;
    lapInvestigation: string;
    price: number;
    duration: number;
    serviceProvider: ServiceProvidersDTO
    doctor: Doctor;
    details: PackageDetail[];

    static fromDTO = (dto: PackagesDTO): PackageTable => ({
        id: dto.id,
        name: dto.name,
        description: dto.description,
        duration: dto.duration,
        price: dto.price,
        providerName: dto.serviceProvider?.user.name ?? "",
        doctorName: dto.doctor?.user.name ?? ""
    });
}

export class PackagesUploaderDTO extends BaseDTO {
    serviceProviderId?:number;
    doctorId?:number;
    file: File
}

export class PackageDetail {
    id?: number;
    detailKey: string;
    detailValue: string;
}