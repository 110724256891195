/* eslint-disable */
import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import {
  useHistory,
} from 'react-router';

import { BookingsDTO, BookingTable } from 'src/stores/backend/PackageBookingStore/types';

const PakcageBookingsComponent: React.FC = () => {
  const {
    backend: {
      packageBooking,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<BookingTable>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
            sorting: false,
          },
          title: i18n.t('PACKAGES'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_PACKAGE_CONFIRMATION'));
                  if (response) {
                    await packageBooking.delete(data.id as number);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const booking: any = data;
                history.push(`/booking-form/view/${booking.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const booking: any = data;
                history.push(`/booking-form/edit/${booking.id}`);
              },
            },
        ],
      }}
      options={{
          search: false,
          pageSize: 10,
          filtering: false,
      }}
      get={async (query) => {
          const res = await packageBooking.getAll(query);
          return {
          data: toJS(res).map((item) => BookingsDTO.fromDTO(item)),
          totalCount: 10,
          page: 1,
          };
      }}
      tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
          },
          {
            field: 'packageName',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'bookingDate',
            title: 'Date',
            type: TableColumnTypes.STRING
          },
          {
            field: 'userName',
            title: 'Doctor',
            type: TableColumnTypes.STRING
          },
          {
            field: 'status',
            title: 'Status',
            type: TableColumnTypes.STRING,
          },
          // {
          //   field: 'scheduledDate',
          //   title: i18n.t('DURATION'),
          //   type: TableColumnTypes.STRING,
          // },
        ]}
      />
    </div>
  );
};

export const packageBookings = baseScreen(PakcageBookingsComponent, {
  allowedRoles: ['ADMIN','NONE'],
});
