/* eslint-disable */
import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon  from '@material-ui/icons/CloudDownload'
import {
  useHistory,
} from 'react-router';
import PackagesDTO, {
  PackageTable
} from './types'

const PakcagesComponent: React.FC = () => {
  const {
    backend: {
      packages,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  return (
    <div>
      <GeneralTableView<PackageTable>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: false,
            paging: false,
            sorting: false,
          },
          title: i18n.t('PACKAGES'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_PACKAGE_CONFIRMATION'));
                  if (response) {
                    await packages.delete(data.id as number);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const packageData: any = data;
                history.push(`/package-form/view/${packageData.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const packageData: any = data;
                history.push(`/package-form/edit/${packageData.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('/package-uploader');
              },
              tooltip: i18n.t('ADD_NEW_PACKAGE'),
            },
            {
              isFreeAction:true,
              icon: DownloadIcon,
              onClick: async () =>{
                const res = await packages.downloadTemplate();
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'packages_template.xlsx'); // Set filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
              },
              tooltip: i18n.t('DOWNLOAD_PACKAGE_TEMPLATE'),
            }
        ],
      }}
      options={{
          search: false,
          pageSize: 10,
          filtering: false,
      }}
      get={async (query) => {
          const res = await packages.getAll(query);
          
          return {
          data: toJS(res).map((item) => PackagesDTO.fromDTO(item)),
          totalCount: 10,
          page: 1,
          };
      }}
      tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
          },
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'providerName',
            title: 'Service Provider',
            type: TableColumnTypes.STRING
          },
          {
            field: 'doctorName',
            title: 'Doctor',
            type: TableColumnTypes.STRING
          },
          {
            field: 'price',
            title: i18n.t('PRICE'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'duration',
            title: i18n.t('DURATION'),
            type: TableColumnTypes.STRING,
          },
        ]}
      />
    </div>
  );
};

export const Packages = baseScreen(PakcagesComponent, {
  allowedRoles: ['ADMIN','NONE'],
});
