/* eslint-disable */
export const packages = '/packages';
export const aPackage = (packageId: number) =>
  `${packages}/${packageId}`;

export const packageTemplate = '/packages/excel/template';

export const uploadServiceProviderPackages = (serviceProviderId: number) =>
  `${packages}/upload/service-provider/${serviceProviderId}`;

export const uploadDoctorPackages = (doctorId: number) =>
  `${packages}/upload/doctor/${doctorId}`;

