/* eslint-disable */
import {
    action,
    observable,
} from 'mobx';
import {
    BackendEntity,
    ListBackendEntity,
    ModelOf,
} from 'utils';
import {
    BaseBackendStore,
} from '../types';
import {
    booking,
    bookings
} from './requests'
import {
    BookingsDTO
} from './types'
import {
    BackendStores,
} from '..';

export class PackageBookingsStore extends BaseBackendStore {
    @observable private _entityList: BookingsDTO[] = [];

    @action public getAll = async (options: any): Promise<BookingsDTO[]> => {
        const response = await this.connections.backend
            .httpGet(`${bookings}`);
            
        return response;
    }

    @observable entityList = new ListBackendEntity(
        this,
        '_entityList',
        this.getAll,
    );

    constructor(
        public parent: BackendStores,
    ) {
        super();
        this.makeObservable();
        this.registerObservableDrivers();
    };

    @observable private _selectedEntity: BookingsDTO;

    @observable selectedEntity = new BackendEntity(
        this,
        '_selectedEntity',
        this.getOne,
    );

    @action
    public async create(data: Partial<BookingsDTO>): Promise<void> {
        try {
            await this.connections.backend.httpPost(bookings, data);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error.response.data.message);
        }
    }

    @action
    public async getOne(id: string): Promise<ModelOf<BookingsDTO>> {
        const response = await this.connections.backend.httpGet(booking(parseInt(id, 10)));
        this.selectedEntity = response;
        return Promise.resolve(response);
    }

    @action update = async (id: number, data: Partial<BookingsDTO>) => {
        try {
            const response = await this.connections.backend.httpPut(booking(id), data);
            return Promise.resolve(response);
        } catch (error: any) {
            return Promise.reject(error.response.data.message);
        }
    };

    @action
    public async delete(id: number): Promise<void> {
        const response = await this.connections.backend
            .httpDelete(booking(id));
        return Promise.resolve(response);
    }
}